import classNames from "classnames";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { MenuLink } from "../../../types/app";

export interface FooterProps {
  className?: string;
}

type QueryType = {
  site: {
    siteMetadata: {
      metaLinks: MenuLink[];
      version: string;
    };
  };
};

const Footer: React.FC<FooterProps> = ({ className }) => {
  const {
    site: {
      siteMetadata: { metaLinks, version },
    },
  }: QueryType = useStaticQuery(query);

  return (
    <div className={classNames("w-full bg-red-accent", className)}>
      <div className="flex flex-col justify-between py-8 md:flex-row container-7xl gap-y-4 md:gap-y-0">
        <p className="text-sm text-white">
          <span>{`© ${new Date().getFullYear()} Psychotherapie Praxis Düsseldorf.`}</span>{" "}
          <span className="text-red-accent">{version}</span>
        </p>
        <nav className="flex flex-col gap-4 md:flex-row">
          {metaLinks.map((link) => (
            <Link key={link.link} to={link.link} className="text-sm text-white">
              {link.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

const query = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        metaLinks {
          name
          link
        }
        version
      }
    }
  }
`;

export default Footer;
