import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import { MenuLink } from "../../types/app";

export interface FyloutMenuProps {
  link: MenuLink;
}

const FlyoutMenu: React.FC<FyloutMenuProps> = ({ link }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMouseEnter = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClickSub = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative"
    >
      <Link
        to={link.link}
        className={classNames(
          "block p-2 text-sm rounded-t text-slate-700 hover:bg-red-accent hover:text-white",
          {
            "rounded-b": !isOpen,
            "rounded-b-none": isOpen,
            "bg-red-accent text-white": isOpen,
          }
        )}
        activeClassName="bg-red-accent text-white"
      >
        {link.name}
      </Link>
      {isOpen && (
        <div className="absolute top-[36px] left-0 z-10 flex flex-col gap-2 bg-white border border-red-accent rounded-b-lg rounded-tr-lg w-60 p-2">
          {link.children!.map((child) => (
            <Link
              key={child.link}
              to={child.link}
              onClick={handleClickSub}
              className="block p-2 text-sm rounded text-slate-700 hover:text-slate-900 hover:bg-slate-50"
              activeClassName="bg-red-accent text-white hover:!bg-red-accent hover:!text-white"
              partiallyActive={false}
            >
              {child.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default FlyoutMenu;
