import { Disclosure as Disc } from "@headlessui/react";
import React from "react";
import IconMinus from "./icons/minus";
import IconPlus from "./icons/plus";

export interface DisclosureNavProps {
  title: string;
  children: React.ReactNode;
}

const DisclosureNav: React.FC<DisclosureNavProps> = ({ title, children }) => {
  return (
    <Disc>
      {({ open }) => (
        <>
          <Disc.Button className="flex flex-row items-center w-full gap-2 p-2">
            <p className="w-full text-sm text-left">{title}</p>
            {open ? (
              <IconMinus className="flex-shrink-0 w-5 h-5" />
            ) : (
              <IconPlus className="flex-shrink-0 w-5 h-5" />
            )}
          </Disc.Button>
          <Disc.Panel className="pl-4 text-gray-500">
            <div className="flex flex-col w-full gap-2 pt-1 pb-2 prose max-w-none prose-slate prose-headings:text-slate-700">
              {children}
            </div>
          </Disc.Panel>
        </>
      )}
    </Disc>
  );
};

export default DisclosureNav;
