import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { MenuLink } from "../../../types/app";
import DisclosureNav from "../disclosure-nav";
import FlyoutMenu from "../flyout-menu";
import IconMenu from "../icons/menu";
import IconX from "../icons/x";

export interface HeaderProps {
  className?: string;
}

type QueryType = {
  site: {
    siteMetadata: {
      menuLinks: MenuLink[];
    };
  };
};

const Header: React.FC<HeaderProps> = ({ className }) => {
  const {
    site: {
      siteMetadata: { menuLinks },
    },
  }: QueryType = useStaticQuery(query);

  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  const handleMobileTriggerClick = React.useCallback(() => {
    setIsMobileNavOpen(!isMobileNavOpen);
  }, [isMobileNavOpen]);

  return (
    <div
      className={classNames(
        "w-full border-b border-b-red-accent z-50",
        className
      )}
    >
      <div className="flex flex-row items-center justify-between gap-4 py-4 md:items-start md:flex-col lg:items-center lg:flex-row container-7xl">
        <Link to="/" className="flex items-center gap-2 flew-row">
          <StaticImage
            src="../../assets/images/logo.gif"
            alt="Logo"
            className="w-12 h-12"
          />
          <span className="text-sm text-slate-700">
            Psychotherapie Praxis
            <br />
            Düsseldorf
          </span>
        </Link>
        <nav className="hidden space-x-1.5 md:flex">
          {menuLinks.map((link) =>
            link.children ? (
              <FlyoutMenu link={link} key={link.link} />
            ) : (
              <Link
                key={link.link}
                to={link.link}
                className="block p-2 text-sm rounded text-slate-700 hover:bg-red-accent hover:text-white"
                activeClassName="bg-red-accent text-white"
              >
                {link.name}
              </Link>
            )
          )}
        </nav>

        <button
          className="md:hidden"
          onClick={handleMobileTriggerClick}
          aria-label="Mobile Menu"
        >
          <IconMenu className="w-6 h-6 text-red-accent" />
        </button>

        <Dialog
          open={isMobileNavOpen}
          onClose={() => setIsMobileNavOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 overflow-y-auto bg-white">
            <div className="flex items-center justify-center">
              <Dialog.Panel className="w-full h-full mx-auto">
                <div className="flex flex-row justify-between px-6 py-4">
                  <Link to="/" className="flex items-center gap-2 flew-row">
                    <StaticImage
                      src="../../assets/images/logo.gif"
                      alt="Logo"
                      className="w-12 h-12"
                    />
                    <span className="text-sm text-slate-700">
                      Psychotherapie Praxis
                      <br />
                      Düsseldorf
                    </span>
                  </Link>
                  <button className="" onClick={handleMobileTriggerClick}>
                    <IconX className="w-6 h-6 text-red-accent" />
                  </button>
                </div>
                <hr className="border-t-red-accent" />
                <div className="flex flex-col gap-2 px-4 py-6">
                  {menuLinks.map((link) =>
                    link.children ? (
                      <DisclosureNav title={link.name} key={link.link}>
                        {link.children.map((link2) => (
                          <div key={link2.link}>
                            <Link
                              to={link2.link}
                              className="block p-2 text-sm rounded text-slate-700 hover:bg-red-accent hover:text-white"
                              activeClassName="bg-red-accent text-white"
                              onClick={handleMobileTriggerClick}
                            >
                              {link2.name}
                            </Link>
                          </div>
                        ))}
                      </DisclosureNav>
                    ) : (
                      <div key={link.link}>
                        <Link
                          to={link.link}
                          className="block p-2 text-sm rounded text-slate-700 hover:bg-red-accent hover:text-white"
                          activeClassName="bg-red-accent text-white"
                          onClick={handleMobileTriggerClick}
                        >
                          {link.name}
                        </Link>
                      </div>
                    )
                  )}
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

const query = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        menuLinks {
          name
          link
          children {
            name
            link
          }
        }
      }
    }
  }
`;

export default Header;
