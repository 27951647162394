exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adiposifit-mdx": () => import("./../../../src/pages/adiposifit.mdx" /* webpackChunkName: "component---src-pages-adiposifit-mdx" */),
  "component---src-pages-aktuelles-mdx": () => import("./../../../src/pages/aktuelles.mdx" /* webpackChunkName: "component---src-pages-aktuelles-mdx" */),
  "component---src-pages-balintgruppen-mdx": () => import("./../../../src/pages/balintgruppen.mdx" /* webpackChunkName: "component---src-pages-balintgruppen-mdx" */),
  "component---src-pages-datenschutzerklaerung-mdx": () => import("./../../../src/pages/datenschutzerklaerung.mdx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-mdx" */),
  "component---src-pages-dr-salamon-berufliche-vita-dr-salamon-mdx": () => import("./../../../src/pages/dr-salamon/berufliche-vita-dr-salamon.mdx" /* webpackChunkName: "component---src-pages-dr-salamon-berufliche-vita-dr-salamon-mdx" */),
  "component---src-pages-dr-salamon-index-mdx": () => import("./../../../src/pages/dr-salamon/index.mdx" /* webpackChunkName: "component---src-pages-dr-salamon-index-mdx" */),
  "component---src-pages-dr-salamon-kontakt-dr-salamon-mdx": () => import("./../../../src/pages/dr-salamon/kontakt-dr-salamon.mdx" /* webpackChunkName: "component---src-pages-dr-salamon-kontakt-dr-salamon-mdx" */),
  "component---src-pages-dr-schoettes-berufliche-vita-dr-schoettes-mdx": () => import("./../../../src/pages/dr-schoettes/berufliche-vita-dr-schoettes.mdx" /* webpackChunkName: "component---src-pages-dr-schoettes-berufliche-vita-dr-schoettes-mdx" */),
  "component---src-pages-dr-schoettes-index-mdx": () => import("./../../../src/pages/dr-schoettes/index.mdx" /* webpackChunkName: "component---src-pages-dr-schoettes-index-mdx" */),
  "component---src-pages-dr-schoettes-kontakt-dr-schoettes-mdx": () => import("./../../../src/pages/dr-schoettes/kontakt-dr-schoettes.mdx" /* webpackChunkName: "component---src-pages-dr-schoettes-kontakt-dr-schoettes-mdx" */),
  "component---src-pages-dr-soljan-berufliche-vita-dr-soljan-mdx": () => import("./../../../src/pages/dr-soljan/berufliche-vita-dr-soljan.mdx" /* webpackChunkName: "component---src-pages-dr-soljan-berufliche-vita-dr-soljan-mdx" */),
  "component---src-pages-dr-soljan-index-mdx": () => import("./../../../src/pages/dr-soljan/index.mdx" /* webpackChunkName: "component---src-pages-dr-soljan-index-mdx" */),
  "component---src-pages-dr-soljan-kontakt-dr-soljan-mdx": () => import("./../../../src/pages/dr-soljan/kontakt-dr-soljan.mdx" /* webpackChunkName: "component---src-pages-dr-soljan-kontakt-dr-soljan-mdx" */),
  "component---src-pages-einzelpsychotherapie-mdx": () => import("./../../../src/pages/einzelpsychotherapie.mdx" /* webpackChunkName: "component---src-pages-einzelpsychotherapie-mdx" */),
  "component---src-pages-gruppenpsychotherapie-mdx": () => import("./../../../src/pages/gruppenpsychotherapie.mdx" /* webpackChunkName: "component---src-pages-gruppenpsychotherapie-mdx" */),
  "component---src-pages-heilhypnose-mdx": () => import("./../../../src/pages/heilhypnose.mdx" /* webpackChunkName: "component---src-pages-heilhypnose-mdx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-links-mdx": () => import("./../../../src/pages/links.mdx" /* webpackChunkName: "component---src-pages-links-mdx" */),
  "component---src-pages-medizinische-gutachten-mdx": () => import("./../../../src/pages/medizinische-gutachten.mdx" /* webpackChunkName: "component---src-pages-medizinische-gutachten-mdx" */),
  "component---src-pages-psychoonkologie-mdx": () => import("./../../../src/pages/psychoonkologie.mdx" /* webpackChunkName: "component---src-pages-psychoonkologie-mdx" */),
  "component---src-pages-psychotherapie-emdr-mdx": () => import("./../../../src/pages/psychotherapie/emdr.mdx" /* webpackChunkName: "component---src-pages-psychotherapie-emdr-mdx" */),
  "component---src-pages-psychotherapie-mdx": () => import("./../../../src/pages/psychotherapie.mdx" /* webpackChunkName: "component---src-pages-psychotherapie-mdx" */),
  "component---src-pages-psychotherapie-psychoanalyse-mdx": () => import("./../../../src/pages/psychotherapie/psychoanalyse.mdx" /* webpackChunkName: "component---src-pages-psychotherapie-psychoanalyse-mdx" */),
  "component---src-pages-psychotherapie-therapieverfahren-mdx": () => import("./../../../src/pages/psychotherapie/therapieverfahren.mdx" /* webpackChunkName: "component---src-pages-psychotherapie-therapieverfahren-mdx" */),
  "component---src-pages-psychotherapie-tiefenpsychologisch-fundierte-psychotherapie-mdx": () => import("./../../../src/pages/psychotherapie/tiefenpsychologisch-fundierte-psychotherapie.mdx" /* webpackChunkName: "component---src-pages-psychotherapie-tiefenpsychologisch-fundierte-psychotherapie-mdx" */),
  "component---src-pages-psychotherapie-verhaltenstherapie-mdx": () => import("./../../../src/pages/psychotherapie/verhaltenstherapie.mdx" /* webpackChunkName: "component---src-pages-psychotherapie-verhaltenstherapie-mdx" */),
  "component---src-pages-raeume-mdx": () => import("./../../../src/pages/raeume.mdx" /* webpackChunkName: "component---src-pages-raeume-mdx" */),
  "component---src-pages-selbsterfahrung-mdx": () => import("./../../../src/pages/selbsterfahrung.mdx" /* webpackChunkName: "component---src-pages-selbsterfahrung-mdx" */),
  "component---src-pages-supervision-mdx": () => import("./../../../src/pages/supervision.mdx" /* webpackChunkName: "component---src-pages-supervision-mdx" */)
}

