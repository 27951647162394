import React from "react";
import Footer from "../components/layout/footer";
import Header from "../components/layout/header";
import ResponsiveSizes from "../components/layout/responsive-sizes";

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <>
    <Header className="flex-shrink-1" />
    <div className="flex flex-grow">{children}</div>
    <Footer className="flex-shrink-1" />
    <ResponsiveSizes />
  </>
);

export default Layout;
